/* Overall Form Container */
.form-ubox-1 {
  display: flex;
  justify-content: center;
  padding: 40px;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background-image: url(../Images/Nu_building2.png);
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Main Form Content Area */
.inner-forms-1 {
  background-color: #fff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px;
  box-sizing: border-box;
  overflow-y: auto;
}

/* Form Elements - Full Width */
.inner-forms-1 input[type="text"],
.inner-forms-1 input[type="email"],
.inner-forms-1 input[type="date"],
.inner-forms-1 input[type="time"],
.inner-forms-1 input[type="number"],
.inner-forms-1 textarea,
.inner-forms-1 select {
  width: 100%;
  padding: 12px 15px;
  margin-bottom: 15px;
  border-radius: 8px;
  border: 2px solid rgb(214, 214, 214);
  background-color: #f9f9f9;
  font-size: 16px;
  color: #495057;
  transition: border-color 0.2s ease-in-out;
  box-sizing: border-box;
}

/* Textarea Specific Styles */
.inner-forms-1 textarea {
  min-height: 100px;
  resize: vertical;
  font-family: inherit;
  padding: 10px;
  overflow-y: auto;
}

/* Focus States */
.inner-forms-1 input:focus,
.inner-forms-1 textarea:focus,
.inner-forms-1 select:focus {
  border-color: #80bdff;
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* Form Groups and Layout */
.form-group {
  margin-bottom: 25px;
}

.form-row {
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
}

.form-row .form-group {
  flex: 1;
  min-width: 0; /* Prevent flex items from overflowing */
}

/* Table Styles */
.program-flow-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  table-layout: fixed;
}

/* Segment Input */
.program-flow-table input[type="text"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
}


.program-flow-table th,
.program-flow-table td {
  padding: 12px;
  border: 1px solid #ddd;
  vertical-align: middle;
}

.program-flow-table th {
  background-color: #f8f9fa;
  font-weight: 600;
}

.program-flow-table input[type="text"] {
  width: 100%;
  box-sizing: border-box;
}

/* Time Range Picker Styles */
.time-range-picker {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.time-inputs {
  display: flex;
  align-items: center;
  gap: 10px;
}

.time-inputs .form-group {
  flex: 1;
  margin-bottom: 0;
  min-width: 0; /* Prevent flex items from overflowing */
}

.time-inputs label {
  display: block;
  font-size: 12px;
  color: #666;
  margin-bottom: 4px;
}

.time-inputs input[type="time"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.time-separator {
  color: #666;
  font-weight: bold;
  padding: 0 5px;
}

.duration-display {
  font-size: 14px;
  color: #666;
  padding: 8px;
  background-color: #f9f9f9;
  border-radius: 4px;
  border: 1px solid #eee;
}


/* Buttons */
.add-btn,
.remove-btn {
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;
  border: none;
}

.add-btn {
  background-color: #28a745;
  color: white;
}

.remove-btn {
  background-color: #dc3545;
  color: white;
}

.add-btn:hover {
  background-color: #218838;
}

.remove-btn:hover {
  background-color: #c82333;
}

/* Form Navigation */
.form-navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.form-navigation button {
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s;
}

.form-navigation button:first-child {
  background-color: #6c757d;
  color: white;
}

.form-navigation button:last-child {
  background-color: #007bff;
  color: white;
}

.form-navigation button:hover {
  opacity: 0.9;
}

/* Responsive Design */
@media (max-width: 768px) {
  .form-ubox-1 {
    padding: 20px;
    flex-direction: column;
  }
  
  .inner-forms-1 {
    max-width: 100%;
    padding: 20px;
  }
  
  .form-row {
    flex-direction: column;
    gap: 15px;
  }
  
  .program-flow-table {
    display: block;
    overflow-x: auto;
  }
  
  .time-inputs {
    flex-direction: column;
    gap: 5px;
  }

  .time-separator {
    display: none;
  }
}

/* Notification */
.notification {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #28a745;
  color: white;
  padding: 12px 24px;
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  animation: fadeInOut 3s ease-in-out;
}

@keyframes fadeInOut {
  0% { opacity: 0; top: 10px; }
  10% { opacity: 1; top: 20px; }
  90% { opacity: 1; top: 20px; }
  100% { opacity: 0; top: 10px; }
}

/* Sidebar */
.sidebar {
  width: 250px;
  background-color: #f8f9fa;
  padding: 20px;
  border-right: 1px solid #dee2e6;
  border-radius: 12px 0 0 12px;
  overflow-y: auto;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar li {
  padding: 12px 15px;
  margin-bottom: 8px;
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.2s;
  display: flex;
  align-items: center;
}

.sidebar li:hover {
  background-color: #e9ecef;
}

.sidebar li.active {
  background-color: #007bff;
  color: white;
}

.check-icon {
  margin-right: 8px;
}

.check-icon.green {
  color: #28a745;
}
/* Main Container - Matched to ProgressTracker */
.org-prog-box {
  border: 1px solid #e0e0e0;
  background-color: #fafafa;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  font-family: 'Roboto', sans-serif;
  overflow: hidden; /* Added to prevent content overflow */
}

.proposal-ttl {
  color: #333;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center; /* Ensured center alignment */
}

/* Content Layout - Matched to ProgressTracker */
.org-progress-tracker {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-height: calc(100vh - 200px); /* Added max-height with overflow */
  overflow: auto; /* Added scroll if content overflows */
  padding: 10px 0; /* Adjusted padding */
  min-width: 0; /* Prevent flex item overflow */
}

/* Progress Bar - Matched to ProgressTracker */
.org-progress-bar-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 10px 0;
  overflow-y: auto;
  max-height: 500px;
  min-height: 300px; /* Ensure minimum height */
}

.org-progress-bar-container::before {
  content: '';
  position: absolute;
  top: 12px;
  bottom: 12px;
  left: 12px;
  width: 2px;
  background-color: #ddd;
  z-index: 0;
}

.org-step-container {
  display: flex;
  align-items: flex-start; /* Changed from center to flex-start */
  margin-bottom: 15px;
  min-height: 60px; /* Added minimum height */
}

.org-progress-step {
  margin-right: 20px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #e0e0e0;
  position: relative;
  z-index: 1;
  flex-shrink: 0; /* Prevent shrinking */
}

.org-step-label {
  font-size: 16px;
  color: #555;
  position: relative;
  margin: 5px 0; /* Adjusted margin */
  word-break: break-word; /* Added to prevent text overflow */
}

.org-reviewer-info, 
.org-timestamp {
  font-size: 12px;
  color: #999;
  margin-left: 0; /* Changed from 8px to 0 */
  margin-top: 4px; /* Added for spacing */
}

/* Action Buttons - Matched to ProgressTracker */
.org-action-buttons {
  display: flex;
  gap: 15px;
  margin-top: 20px;
  justify-content: flex-end;
  align-items: flex-end;
  flex-wrap: wrap; /* Allow buttons to wrap on small screens */
  width: 100%; /* Ensure full width */
}

.org-action-button {
  display: flex;
  height: 37px;
  width: 150px;
  border-radius: 50px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.org-action-button:hover {
  background-color: #0056b3;
}

.org-tracker-content-wrapper {
  display: flex;
  gap: 30px;
  width: 100%;
  min-height: 400px; /* Ensure minimum height */
}

/* Left Column - Tracker Steps */
.org-tracker-steps-column {
  flex: 2;
  min-width: 0; /* Prevent flex overflow */
  display: flex;
  flex-direction: column;
}

/* Loading Spinner - Kept your original styling */
.org-floating-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.org-spinner {
  color: #1976d2;
  width: 50px !important;
  height: 50px !important;
}

/* Feedback Section - Matched to ProgressTracker */
/* Add these styles to your existing OrgTrackerViewer.css */

/* Feedback Container - Matched to ProgressTracker */
.org-feedback-container {
  position: sticky;
  top: 20px;
  background: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  border: 1px solid #e0e0e0;
  height: fit-content;
}

.org-feedback-column {
  flex: 1;
  min-width: 300px;
  max-width: 400px;
  position: relative;
}

.org-feedback-container h4 {
  margin-top: 0;
  color: #333;
  font-size: 18px;
}

.org-feedback-container p {
  color: #666;
  font-size: 14px;
  margin-bottom: 15px;
}

/* Rating Stars */
.org-rating-stars {
  display: flex;
  gap: 8px;
  margin: 15px 0;
}

.org-star-filled {
  color: #FFD700;
  cursor: pointer;
  font-size: 28px;
}

.org-star-empty {
  color: #ccc;
  cursor: pointer;
  font-size: 28px;
  transition: color 0.2s;
}

.org-star-empty:hover {
  color: #FFD700;
}

/* Textarea */
.org-feedback-textarea {
  width: 93%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 15px;
  font-family: inherit;
  resize: vertical;
  min-height: 120px;
  transition: border-color 0.3s;
  resize: none;
}

.org-feedback-textarea:focus {
  outline: none;
  border-color: #4CAF50;
}

/* Button */
.org-feedback-button-container {
  display: flex;
  justify-content: flex-end;
}

.org-feedback-submit-button {
  background-color: #4CAF50;
  color: white;
  padding: 8px 16px;
  text-transform: none;
  font-weight: 500;
}

.org-feedback-submit-button:hover {
  background-color: #45a049;
}

.org-feedback-submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Thank You Message */
.org-feedback-thank-you {
  display: flex;
  align-items: center;
  color: #4CAF50;
  font-weight: bold;
  padding: 10px;
  background-color: #f0fff0;
  border-radius: 4px;
  justify-content: center;
}

/* Error Message */
.org-feedback-error {
  margin-bottom: 10px;
  color: red;
  font-size: 14px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .org-feedback-container {
    margin-left: 0;
    margin-top: 20px;
  }
}

/* Responsive Adjustments - Matched to ProgressTracker */
@media (max-width: 768px) {
  .org-progress-tracker {
    flex-direction: column;
  }
  
  .org-progress-bar-container {
    max-height: none;
  }
  
  .org-action-buttons {
    justify-content: center;
  }
}
/* EventTrackerList.css */
/* Container Styling */
.event-tracker-container {
  display: flex;
  flex-direction: column;
  height: 600px;
  max-width: 1300px;
  margin: 20px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  font-family: 'Inter', sans-serif;
}

/* Heading Styling */
.event-tracker-container h2 {
  text-align: left;
  color: #2c3e50;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 600;
}

/* Error Message Styling */
.event-tracker-error {
  text-align: center;
  color: #e74c3c;
  background-color: #f8d7da;
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
}

/* Filter Buttons Styling */
.event-tracker-filters {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.filter-button {
  padding: 8px 16px;
  border: 1px solid #ddd;
  border-radius: 20px;
  background-color: #f9f9f9;
  color: #333;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.filter-button.active {
  background-color: #34495e;
  color: #ffffff;
  border-color: #34495e;
}

.filter-button:hover {
  background-color: #34495e;
  color: #ffffff;
}

/* Table Wrapper Styling */
.event-tracker-table-wrapper {
  max-height: 500px; /* Fixed height */
  overflow-y: auto; /* Enable vertical scrolling */
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}

/* Table Styling */
.event-tracker-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
}

.event-tracker-table th,
.event-tracker-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
  font-size: 14px;
}

.event-tracker-table th {
  background-color: #f8f9fa;
  font-weight: 600;
  color: #333;
  position: sticky;
  top: 0; /* Make the header sticky */
  z-index: 1; /* Ensure the header stays above the rows */
}

.event-tracker-table tr:hover {
  background-color: #f9f9f9;
}

/* Status Badge Styling */
.status-badge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
}

.status-badge.pending {
  background-color: #8c9cef;
  color: #333;
}

.status-badge.approved {
  background-color: #4CAF50;
  color: #ffffff;
}

.status-badge.declined {
  background-color: #D9534F;
  color: #ffffff;
}

/* Loading Spinner Styling */
.loading-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px; /* Adjust height as needed */
}

.spinner {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #34495e; /* Dark blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.event-tracker-table td:nth-child(5) {
  font-weight: 500; /* Make the current step text slightly bold */
  color: #34495e; /* Dark blue color for better visibility */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner p {
  margin-top: 10px;
  font-size: 16px;
  color: #333;
}

/* Responsive Design */
@media (max-width: 768px) {
  .event-tracker-container {
    padding: 15px;
  }

  .event-tracker-table th,
  .event-tracker-table td {
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .event-tracker-container {
    padding: 10px;
  }

  .event-tracker-table th,
  .event-tracker-table td {
    padding: 8px;
  }

  .filter-button {
    padding: 6px 12px;
    font-size: 12px;
  }
}
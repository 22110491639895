/* Main Container */
.prog-box {
  border: 1px solid #e0e0e0;
  background-color: #fafafa;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  font-family: 'Roboto', sans-serif;
  overflow: hidden; /* Prevent content from overflowing */
}

/* Content Layout */
.progress-content {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  max-height: calc(100vh - 200px); /* Adjust based on your needs */
  overflow: auto; /* Add scroll if content overflows */
}

.progress-tracker {
  flex: 2;
  display: flex;
  flex-direction: column;
  min-width: 0; /* Prevent flex item overflow */
}

/* Progress Bar */
.progress-bar-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 10px 0;
  overflow-y: auto; /* Add scroll if steps overflow */
  max-height: 500px; /* Limit height */
}

.progress-bar-container::before {
  content: '';
  position: absolute;
  top: 12px;
  bottom: 12px;
  left: 12px;
  width: 2px;
  background-color: #ddd;
  z-index: 0;
}

.step-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
  min-height: 60px;
}

.progress-step {
  margin-right: 20px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #e0e0e0;
  position: relative;
  z-index: 1;
  flex-shrink: 0;
}

.step-label {
  font-size: 16px;
  color: #555;
  position: relative;
  margin: 5px 0;
  word-break: break-word; /* Prevent text overflow */
}

.reviewer-info, .timestamp {
  font-size: 12px;
  color: #999;
  margin-left: 0;
  margin-top: 4px;
}

/* Edit Tracker Section */
.edit-tracker {
  display: flex;
  flex-direction: column;
  background-color: #0047ab;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
}

.edit-tracker-title {
  text-align: center;
  color: yellow;
  font-size: 20px;
  margin-bottom: 20px;
}

.edit-tracker-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.checkbox-container {
  font-size: 16px;
  color: white;
  display: flex;
  align-items: center;
}

.checkbox-container input {
  margin-right: 10px;
  transform: scale(1.5);
}

/* Save Button */
.save-button {
  margin-top: 20px;
  background-color: yellow;
  color: blue;
  font-weight: bold;
  border-radius: 8px;
  width: 100px;
  padding: 8px 0;
}

/* Action Buttons */
.action-buttons {
  display: flex;
  gap: 15px;
  margin-top: 20px;
  justify-content: flex-end;
  align-items: flex-end;
  flex-wrap: wrap; /* Allow buttons to wrap on small screens */
}

.action-button {
  display: flex;
  height: 37px;
  width: 150px;
  border-radius: 50px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.action-button:hover {
  background-color: #0056b3;
}

/* PDF Download */
.pdf-download-container {
  margin-top: 20px;
}

/* Feedback Container */
.feedback-container {
  flex: 1;
  min-width: 300px;
  max-width: 400px;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: fit-content;
}

.feedback-container h4 {
  margin-top: 0;
  color: #333;
  font-size: 18px;
}

.feedback-container p {
  color: #666;
  font-size: 14px;
  margin-bottom: 15px;
}

.feedback-textarea {
  width: 95%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 15px;
  font-family: inherit;
  resize: none;
  min-height: 100px;
}

.feedback-submit-button {
  background-color: #4CAF50;
    color: white;
    padding: 8px 16px;
    text-transform: none;
    font-weight: 500;
}

.feedback-submit-button:hover {
  background-color: #45a049;
}

.feedback-submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.feedback-thank-you {
  display: flex;
  align-items: center;
  color: #4CAF50;
  font-weight: bold;
  padding: 10px;
  background-color: #f0fff0;
  border-radius: 4px;
  justify-content: center;
}

.feedback-error {
  margin-bottom: 10px;
  color: red;
  font-size: 14px;
}

/* Loading Spinner */
.loading-spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  gap: 20px;
}

.loading-spinner-container p {
  font-size: 18px;
  color: #333;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .progress-content {
    flex-direction: column;
  }
  
  .feedback-container {
    margin-left: 0;
    margin-top: 20px;
    max-width: 100%;
  }
  
  .progress-bar-container {
    max-height: none;
  }
}
/* Main Container */
.org-submitted-forms-container {
  max-width: 1300px;
  margin: 2rem auto;
  padding: 2rem;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
}

/* Heading */
.org-submitted-forms-container h2 {
  color: #2d3748;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  font-weight: 600;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #edf2f7;
}

/* Error Message */
.error-message {
  padding: 0.75rem 1rem;
  background: #fff5f5;
  color: #e53e3e;
  border-radius: 6px;
  margin-bottom: 1.5rem;
  font-size: 0.9rem;
  border-left: 4px solid #e53e3e;
}

/* Filter Buttons */
.filter-buttons {
  display: flex;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
}

.filter-button {
  padding: 0.5rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background: #f7fafc;
  color: #4a5568;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.filter-button.active,
.filter-button:hover {
  background: #4299e1;
  color: white;
  border-color: #4299e1;
}

/* Table Wrapper */
.table-wrapper {
  border: 1px solid #e2e8f0;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.04);
}

/* Forms Table */
.forms-table {
  width: 100%;
  border-collapse: collapse;
  background: white;
}

.forms-table th {
  background: #f8fafc;
  color: #4a5568;
  font-weight: 600;
  font-size: 0.875rem;
  text-align: left;
  padding: 1rem 1.25rem;
  border-bottom: 1px solid #e2e8f0;
  position: sticky;
  top: 0;
}

.forms-table td {
  padding: 1rem 1.25rem;
  border-bottom: 1px solid #edf2f7;
  font-size: 0.875rem;
  color: #2d3748;
}

.forms-table tr:last-child td {
  border-bottom: none;
}

.forms-table tr:hover {
  background: #f8fafc;
  cursor: pointer;
}

/* Status Badge */
.status-badge {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  border-radius: 12px;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: capitalize;
}

.status-badge.pending {
  background: #bee3f8;
  color: #2b6cb0;
}

.status-badge.approved {
  background: #c6f6d5;
  color: #276749;
}

.status-badge.declined {
  background: #fed7d7;
  color: #9b2c2c;
}

/* Action Buttons */
.action-buttons {
  display: flex;
  gap: 0.5rem;
}

.view-button {
  padding: 0.5rem 1rem;
  background: #edf2f7;
  color: #2d3748;
  border: none;
  border-radius: 6px;
  font-size: 0.8125rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.view-button:hover {
  background: #e2e8f0;
}

/* No Forms Message */
.no-forms-message {
  text-align: center;
  padding: 2rem;
  color: #718096;
  font-size: 0.9rem;
}

/* Loading Spinner */
.loading-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
}

.spinner {
  width: 2.5rem;
  height: 2.5rem;
  border: 3px solid rgba(66, 153, 225, 0.2);
  border-top-color: #4299e1;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

.loading-spinner p {
  color: #4a5568;
  font-size: 0.9rem;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

/* Responsive Design */
@media (max-width: 768px) {
  .org-submitted-forms-container {
    padding: 1.5rem;
  }
  
  .forms-table th,
  .forms-table td {
    padding: 0.75rem;
  }
  
  .action-buttons {
    flex-direction: column;
    gap: 0.25rem;
  }
  
  .view-button {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .org-submitted-forms-container {
    padding: 1rem;
  }
  
  .filter-buttons {
    gap: 0.5rem;
  }
  
  .filter-button {
    padding: 0.4rem 0.8rem;
    font-size: 0.8rem;
  }
  
  .forms-table {
    display: block;
    overflow-x: auto;
  }
}